import React from 'react';
import { FAQS } from '../../helpers/constants';

// COMPOENENTS
import PageBanner from '../../components/general/PageBanner';

function FAQsPage() {
    return (
        <>
            <PageBanner
                heading='Frequently Asked Questions'
                text=""
            ></PageBanner>

            <section className='py-5'>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-lg-7 mx-auto'>
                            <div className='accordion accordio-minimal' id='faqs'>
                                {FAQS.map((item, index) => {
                                    return (
                                        <div
                                            className='accordion-item'
                                            key={index}
                                            data-aos='fade-up'
                                            data-aos-delay={`${index * 100}`}
                                        >
                                           <div className='accordion-body'>{item.answer}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FAQsPage;
