/* -----------------------------------------------------------------------------------------

    THSI FILE CONTAINS SOME STATIC DATA TO FILL SOME DIFFERENT COMPONENTS

----------------------------------------------------------------------------------------- */

import React from 'react';

/* FAQS LIST - ADD OR DELETE FROM THEM AS YOU NEED */
export const FAQS = [
    {
        question: 'FAQS',
        answer: (
            <div className='text-muted'>
             
                <h4>VideoRapid - A Brand New Web3 Video Experience</h4>
             

               
                <p className='mb-2'>
                 

With the revolution of Web3, a new era is beginning to elevate your video-watching experience: VideoRapid! VideoRapid offers a unique platform for movie enthusiasts, content creators, and anyone looking to leverage the advantages of blockchain technology.
</p>
<h4>Empowered by Web3 Technology</h4>
<p className='mb-2'>

VideoRapid is built on the Rapid Blockchain but is also a Web3 video streaming application compatible with other blockchain networks. This allows content creators to host their videos on the blockchain, and users can access these contents using cryptocurrencies.
</p>

<h4>Easy to Use, Fantastic Experience</h4>
<p className='mb-2'>


We understand that Web3 applications can be complex, but VideoRapid changes that. With its user-friendly interface, everyone can easily use the application and seamlessly benefit from the advantages of the Web3 world.
</p>

<h4>Subscription-Based Content, User Control</h4>
<p className='mb-2'>


VideoRapid follows the popular Subscription Video on Demand (SVOD) model. Now, you can set your subscription prices and create your channels. Whether it's free or filled with premium content, the control is in your hands.
</p>

<h4>Transparent and Instant Earnings</h4>
<p className='mb-2'>


VideoRapid operates transparently, allowing content creators to receive their earnings instantly. All transactions on the blockchain are visible, providing users with complete transparency.
</p>

<h4>Speed Up Entertainment with VideoRapid!</h4>
<p className='mb-2'>


VideoRapid accelerates the entertainment world and brings you the benefits of Web3. Sign up now, create your channel, and enjoy a unique Web3 video experience.
</p>

<p className='mb-2'>
VideoRapid - The New Name of Entertainment Powered by Web3
                </p>
            </div>
        ),
    }
   
];
